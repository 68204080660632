import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import SEO from "../components/seo"

import bank from "../images/bank.svg"
import signature from "../images/signature.svg"
import stamp from "../images/stamp.svg"
import coins from "../images/coins.svg"

import { min } from "../utils/theme"

const Section = styled.section`
  padding: 2rem;
  max-width: 1250px;
  margin: 0 auto;
`

const List = styled.ul`
  list-style: none;
  max-width: 1000px;

  padding-left: 0;

  ${min("small")(css`
    padding-left: 1.5rem;
  `)}
`

const ListItem = styled.li`
  display: flex;
  flex-direction: column;

  margin-bottom: 2rem;

  ${min("small")(css`
    flex-direction: row;
    align-items: center;
  `)}
`

const Icon = styled.img`
  height: 100px;
  width: 100px;
  flex-shrink: 0;
  margin-right: 1.2rem;
`

const PageTitle = styled.h2`
  margin-bottom: 2rem;
`

const SubTitle = styled.h3`
  margin-bottom: 1.5rem;
`

const Text = styled.span`
  font-size: 18px;
`

const CorporateFinance = () => (
  <Layout>
    <SEO title="Corporate Finance" />
    <Section>
      <PageTitle>
        Autamme asiakasyritystämme mm. seuraavissa muutostilanteissa:
      </PageTitle>
      <List>
        <ListItem>
          <Icon src={stamp} />
          <div>
            <SubTitle>Yrityksen tai liiketoiminnan myynti</SubTitle>
            <Text>
              Palvelumme kattaa mm. yrityksen arvonmäärityksen,
              ostajakandidaattien etsimisen, avustamisen kaupallisissa
              neuvotteluissa, kauppaprosessin suunnittelun, valvonnan ja
              seurannan, muiden yhteistyökumppanien, kuten juridisten
              neuvonantajien, tilintarkastajien ja verokonsulttien, työn
              koordinoinnin sekä avustamisen tiedottamisessa sekä julkisuudessa
              että henkilöstölle.
            </Text>
          </div>
        </ListItem>
        <ListItem>
          <Icon src={bank} />
          <div>
            <SubTitle>Yrityksen tai liiketoiminnan osto</SubTitle>
            <Text>
              Avustamme sopivien ostokohteiden etsimisessä, ostokohteiden
              arvonmäärityksen tekemisessä, kaupallisissa neuvotteluissa,
              tarvittavien asiantuntijoiden, mm. juridisten neuvonantajien,
              tilintarkastajien sekä veroasiantuntijoiden työn koordinoinnissa
              sekä tiedottamisen suunnittelussa ja toteuttamisessa.
            </Text>
          </div>
        </ListItem>
        <ListItem>
          <Icon src={signature} />
          <Text>
            <SubTitle>Sukupolvenvaihdos</SubTitle>
            <Text>
              Mikäli yritykselle löytyy jatkaja omasta perheestä, on
              sukupolvenvaihdos luonteva tapa varmistaa yrityksen tulevaisuus.
              <br />
              Sukupolvenvaihdoksen onnistunut toteuttaminen vaatii
              perusteellisen suunnittelun vaiheiden ja ajoituksen suhteen
              verovaikutusten optimoimiseksi. Avustamme
              sukupolvenvaihdosprosessin suunnittelussa ja toteutuksessa sekä
              muiden neuvonantajien, mm. lakimiehet, tilintarkastajat ja
              veroasiantuntijat, töiden koordinoinnissa.
            </Text>
          </Text>
        </ListItem>
        <ListItem>
          <Icon src={coins} />
          <div>
            <SubTitle>Muut muutostilanteet</SubTitle>
            <Text>
              Muita suunnittelua ja neuvonantoa vaativia muutostilanteita ovat
              esimerkiksi yritysfuusiot ja -diffuusiot, yrityksen listaaminen,
              yhteisyritysten perustaminen sekä osakassopimusten laatiminen.
              <br />
              Credos Oy:n yhteistyöverkostosta löytyy asiantuntijat kaikkiin
              yritystoiminnassa esiin tuleviin muutostilanteisiin. Käytettäessä
              asiantuntijaverkoston ammattilaisia Credos Oy ottaa vastuun työn
              koordinoinnista ja aikataulutuksesta parhaan lopputuloksen
              varmistamiseksi.
            </Text>
          </div>
        </ListItem>
      </List>
    </Section>
  </Layout>
)

export default CorporateFinance
